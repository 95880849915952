export default {
  AUTHORIZATION: 'AUTHORIZATION',
  SAVE_IN: 'SAVE_IN',
  USER: 'USER',
  IS_NEW_USER: 'IS_NEW_USER',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  THERE_ACCESS: 'THERE_ACCESS',
  QUESTIONNARIE: 'QUESTIONNARIE',
  EMAIL_FOR_NAV: 'EMAIL_FOR_NAV',
};
