import React, { Component, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { $win } from '../../helpers';
import { scrollingDirection } from '../../utils';

interface IProps {
  children?: ReactNode;
  className?: string;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 'app',
  head: 'app__head',
  foot: 'app__foot',
  body: 'app__body',
  sidebar: 'app__body-side',
  main: 'app__body-main',
};

const layoutPart: FC<IParts> | Function = (children, className): ReactElement | Function => (
  <div className={className}>{children}</div>
);

export const Head: Function = ({ children }: IProps): ReactNode => {
  const $header = useRef<HTMLDivElement>(null);

  const [scrolledName, setScrolled] = useState('');

  const scrolledDown = (scrolled: string) => {
    const { current } = $header;

    const $height = current ? current.clientHeight : 0;

    if ($win.scrollY > $height) {
      scrolled = ' scrolled';
    } else {
      scrolled = '';
    }
    return scrolled;
  };

  const scrolledUp = (scrolled: string) => {
    if ($win.scrollY === 0) {
      scrolled = '';
    } else {
      scrolled = ' scrolled';
    }
    return scrolled;
  };

  const isScrolled: EventListener = (): void => {
    let scrolled: string = '';

    const direction = scrollingDirection($win.scrollY);

    scrolled = direction !== -1 ? scrolledUp(scrolled) : scrolledDown(scrolled);

    setScrolled(scrolled);
  };

  useEffect(() => {
    $win!.addEventListener('scroll', isScrolled);

    return function cleanUp() {
      $win!.removeEventListener('scroll', isScrolled);
    };
  });

  return (
    <div className={`${field.head} ${scrolledName}`} ref={$header}>
      {children}
    </div>
  );
};

export const Body: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.body} ${className}`);

export const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

export const Sidebar: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.sidebar);

export const Main: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.main);

export const Authorization: Function = ({ children }: IProps): ReactNode =>
  layoutPart(<div className="auth">{children}</div>, field.app);

class Layout extends Component<IProps> {
  public static Head: Function = Head;

  public static Body: Function = Body;

  public static Foot: Function = Foot;

  public static Sidebar: Function = Sidebar;

  public static Main: Function = Main;

  public static Auth: Function = Authorization;

  render(): JSX.Element {
    const { children } = this.props;

    return <div className={field.app}>{children}</div>;
  }
}

export default Layout;
