import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  iconName?: string;
  label?: ReactNode;
  placeholderTranslate?: ReactNode;
  className?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: Function | ReactEventHandler;
  children?: ReactNode;
}

const InputTransparent: FC<IProps> = function ({
  iconName,
  className = '',
  type = 'text',
  placeholder = '',
  placeholderTranslate,
  value = '',
  onChange,
  children,
  label,
}): ReactElement {
  const onInput: ReactEventHandler = (e): void => {
    onChange && onChange(e);
  };

  const isParams = {
    onChange: onInput,
    placeholder,
    value,
    type,
  };

  const renderInput: Function = (): ReactNode => {
    return (
      <div className={`input--transparent ${className}`}>
        {iconName && <span className={iconName} />}
        <input {...isParams} required autoComplete="new-password" />
        {label && <label>{label}</label>}
        {placeholderTranslate && <span>{placeholderTranslate}</span>}
        {children}
      </div>
    );
  };

  return renderInput();
};

export default InputTransparent;
