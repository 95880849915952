import { IPartnersAction, IPartnersState } from '../../services/IPartners';
import types from './types';

const initialState: IPartnersState = {
  partnersList: null,
};

export default function partnersReducer(state: IPartnersState = initialState, action: IPartnersAction): IPartnersState {
  switch (action.type) {
    case types.GET_PARTNERS_LIST:
      return { ...state, partnersList: action.partnersList };

    default:
      return state;
  }
}
