import 'swiper/css/swiper.css';
import './assets/less/app.less';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Preloader } from './components/ui';
import { $root, CONFIG } from './helpers';
import * as serviceWorker from './serviceWorker';
import { store } from './store/configureStore';
import { useHistory } from 'react-router';

const App = lazy(() => import('./App'));

/**
 * Initialize Google Analytics
 * */

// const some: ReactEventHandler = (e) => {
//   const search = e.target as HTMLInputElement;
//
//   console.log(search.value);
// };

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Preloader />}>
      <App />
    </Suspense>
  </Provider>,
  $root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
