import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  toggle?: boolean;
  children?: ReactNode;
  onToggle?(): void;
}

const ButtonMobile: FC<IProps> = function ({ toggle, children, onToggle }): ReactElement {
  const onClickButton: ReactEventHandler = (): void => {
    onToggle && onToggle();
  };

  const renderButton: Function = (): ReactNode => {
    const isActive = toggle ? 'active' : '';

    return (
      <div className={`button--mobile ${isActive}`} onClick={onClickButton}>
        {children && children}
      </div>
    );
  };

  return renderButton();
};

export default ButtonMobile;
