import ReactGA from 'react-ga';
import { useHistory } from 'react-router';
import { CONFIG } from './config';
import { $win } from './index';

function setUpUrl() {
  const url = `${$win.location.pathname}${$win.location.search}`;

  ReactGA.initialize(CONFIG.GOOGLE_ANALYTICS_ID);
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname)
}

export default function HistoryListener() {
  // return null;
  // const history = useHistory();
  //
  // history.listen(setUpUrl);
  //
  // setUpUrl();
  return null;
}
