/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PAGE } from '../../router/client';

interface IProps {
  component: any;
  isAuthenticated: boolean;
  rest: any;
}

const PrivateRoute: Function = ({ component, isAuthenticated, ...rest }: IProps): ReactNode => {
  const routeComponent = (props: any) =>
    isAuthenticated ? React.createElement(component, props) : <Redirect to={{ pathname: PAGE.AUTH }} />;

  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
