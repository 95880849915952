let valueScrolled = 0;

export default function scrollingDirection(scrollTop: number): number {
  const st = scrollTop;

  const result = st > valueScrolled ? -1 : 1;

  valueScrolled = st;
  return result;
}
