import { IAuthAction, IAuthState } from '../../services/IAuth';
import { checkExpires } from '../../utils';
import types from './types';

const initialState: IAuthState = {
  token: checkExpires('access_token', null),
  saveIn: checkExpires('saveIn', null),
  // user: checkExpires('user', null),
  user: null,
  emailForNav: checkExpires('emailForNav', null),
  isNewUser: checkExpires('isNewUser', true),
  questionnaire: checkExpires('questionnaire', true),
  thereAccess: checkExpires('thereAccess', false),
  errorMessage: null,
};

export default function authReducer(state: IAuthState = initialState, action: IAuthAction): IAuthState {
  switch (action.type) {
    case types.AUTHORIZATION:
      return { ...state, token: action.token };

    case types.SAVE_IN:
      return { ...state, saveIn: action.saveIn };

    case types.USER:
      return { ...state, user: action.user };

    case types.IS_NEW_USER:
      return { ...state, isNewUser: action.isNewUser };

    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    case types.THERE_ACCESS:
      return { ...state, thereAccess: action.thereAccess };

    case types.QUESTIONNARIE:
      return { ...state, questionnaire: action.questionnaire };
    case types.EMAIL_FOR_NAV:
      return { ...state, emailForNav: action.emailForNav };

    default:
      return state;
  }
}
