import React, { FC, ReactElement } from 'react';
import logo from '../../assets/images/general/logo-min.png';

const Preloader: FC = (): ReactElement => {
  return (
    <div className="preloader">
      <div className="preloader__spinner">
        <img src={logo} alt="logo sibur" />
      </div>
    </div>
  );
};

export default Preloader;
