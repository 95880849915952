import { IHomeworkAction, IHomeworkState } from '../../services/IHomework';
import types from './types';

const initialState: IHomeworkState = {
  homework: null,
  successCourse: null,
  errorMessage: null,
  isSavingAnswer: false,
};

export default function homeworkReducer(state: IHomeworkState = initialState, action: IHomeworkAction): IHomeworkState {
  switch (action.type) {
    case types.HOMEWORK_LIST:
      return { ...state, homework: action.homework };

    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    case types.SUCCESS_COURSE:
      return { ...state, successCourse: action.successCourse };

    case types.SET_SAVING_STATE:
      return { ...state, isSavingAnswer: state.isSavingAnswer };

    default:
      return state;
  }
}
