import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import additionReducer from '../containers/addition/reducer';
import authReducer from '../containers/auth/reducer';
import homeReducer from '../containers/home/reducer';
import homeworkReducer from '../containers/homework/reducer';
import lessonReducer from '../containers/lesson/reducer';
import partnersReducer from '../containers/partners/reducer';
import profileReducer from '../containers/profile/reducer';
import generalReducer from '../containers/general/reducer';

export const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  general: generalReducer,
  auth: authReducer,
  profile: profileReducer,
  addition: additionReducer,
  lesson: lessonReducer,
  homework: homeworkReducer,
  home: homeReducer,
  partners: partnersReducer,
});
