import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import CustomSelect from '../custom/CustomSelect';

interface IProps {
  children: ReactNode;
  selection?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  isChange?: boolean;
}

const SelectPrimary: FC<IProps> = ({ selection, children, placeholder, label, className = '', isChange }): ReactElement => {
  const [state, setState] = useState({
    isSelection: false,
  });


  const params = {
    ...(state.isSelection ? {
      selection,
      placeholder
    } : { selection: false, placeholder }),
    className,
  };

  useEffect(() => {
    if (isChange) {
      setState({
        ...state,
        isSelection: isChange
      })
    }
  }, [isChange])

  return (
    <div className="select--primary">
      {label && <label>{label}</label>}
      <CustomSelect {...params}>{children}</CustomSelect>
    </div>
  );
};

export default SelectPrimary;
