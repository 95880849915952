import { useState } from 'react';

type IUseCustomToggleAction = [boolean, Function];

export default function useCustomToggle(toggleValue: boolean): IUseCustomToggleAction {
  const [toggle, setIsToggle] = useState<boolean>(toggleValue);

  const onToggle: Function = (value: boolean | null = null) => setIsToggle(value !== null ? value : !toggle);

  return [toggle, onToggle];
}
