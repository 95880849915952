import React, { FC, ReactElement, ReactNode } from 'react';
import SimpleBar from 'simplebar-react';

interface IProps {
  children: ReactNode;
  onScroll?(): void;
}

const CustomScroll: FC<IProps> = (props): ReactElement => {
  const { children, onScroll } = props;

  const defaultOptions = {
    autoHide: true,
    style: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
    },
    ...(onScroll && { onScroll }),
    ...props,
  };

  return <SimpleBar {...defaultOptions}>{children}</SimpleBar>;
};

export default CustomScroll;
