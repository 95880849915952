import { CONFIG } from './config';
import $history from './historyCreate';
import HistoryListener from './historyListener';

const $doc = document;

const $win = window;

const $body = $doc.body;

const $root = $doc.getElementById('root');

export { $doc, $win, $body, $root, CONFIG, $history, HistoryListener };
