import React, { FC, ReactElement } from 'react';
import certificateImage from '../../assets/images/general/certificate.jpg';

interface IProps {
  logo: string;
  title: string;
  firstName: string;
  lastName: string;
  subtitle: string;
}

const Certificate: FC<IProps> = function ({ logo, title, firstName, lastName, subtitle }): ReactElement {
  return (
    <div className="certificate">
      <img src={certificateImage}
           alt={title}
           className="certificate__image" />
      {/*<div className="certificate__title">*/}
      {/*  <img src={logo} alt="certificate result" />*/}
      {/*</div>*/}
      {/*<div className="certificate__data">*/}
      {/*  <h1>{title}</h1>*/}
      {/*  <div className="certificate__data-field">{firstName}</div>*/}
      {/*  <div className="certificate__data-field">{lastName}</div>*/}
      {/*</div>*/}
      {/*<div className="certificate__subtitle">{subtitle}</div>*/}
    </div>
  );
};

export default Certificate;
