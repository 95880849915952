interface IRouters {
  [key: string]: string;
}

export const PAGE: IRouters = {
  HOME: '/',
  ABOUT: '/about',
  CONTACTS: '/contacts',
  PARTNERS: '/partners',
  PROFILE: '/profile',
  LESSON: '/lessons',
  HOMEWORK: '/homework',
  ADDITION: '/addition',
  AUTH: '/auth',
  ERROR: '/404',
  ANY: '*',
};

export const AUTH: IRouters = {
  SIGN_IN: `${PAGE.AUTH}/sign-in`,
  SIGN_UP: `${PAGE.AUTH}/sign-up`,
  RESET_PASSWORD: `${PAGE.AUTH}/reset-password`,
};

export const PROFILE: IRouters = {
  WORKSHEET: `${PAGE.PROFILE}/worksheet`,
  HOMEWORK: `${PAGE.HOMEWORK}/homework`,
  LESSON: `${PAGE.LESSON}/lesson`,
};
