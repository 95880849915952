import { ILessonAction, ILessonState } from '../../services/ILesson';
import types from './types';

const initialState: ILessonState = {
  lessonList: null,
  errorMessage: null,
};

export default function lessonReducer(state: ILessonState = initialState, action: ILessonAction): ILessonState {
  switch (action.type) {
    case types.LESSON_LIST:
      return { ...state, lessonList: action.lessonList };

    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    default:
      return state;
  }
}
