import React, { FC, ReactElement, ReactNode } from 'react';

interface IProps {
  label?: ReactNode;
  className?: string;
  value?: number;
  children?: ReactNode;
}

const ProgressBar: FC<IProps> = function ({ className = '', children, value, label }): ReactElement {
  return (
    <div className={`progress--primary ${className}`}>
      {label && <label>{label}</label>}
      <div className="progress-bar">
        <div className="progress-bar__value" style={{ width: `${value}%` }}>
          {value} %{children}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
