import get from 'lodash/get';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { IReduxStore } from '../../store/serviceReducer';

interface IProps {
  contacts?: any;
}

const Controls: FC<IProps> = function ({ contacts }): ReactElement {
  const data = get(contacts, 'data', {});

  return (
    <div className="controls">
      <div className="controls__item">
        <a
          href={`tg://resolve?domain=${get(data, 'telegram', '/#')}`}
          target="_blank"
          rel="noopener noreferrer"
          className="icon--telegram"
        />
      </div>
      <div className="controls__item">
        <a
          href={`https://api.whatsapp.com/send?phone=${get(data, 'whatsapp', '+7')}`}
          target="_blank"
          rel="noopener noreferrer"
          className="icon--area"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  contacts: store.home.contacts!,
});

export default connect(mapStateToProps)(Controls);
