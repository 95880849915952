import React, { FC, ReactElement, ReactEventHandler, ReactNode, useState } from 'react';
import CustomSwitch from '../custom/CustomSwitch';

interface IProps {
  label?: ReactNode;
  className?: string;
  type?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  inactive?: boolean;
  onChange?: Function;
  children?: ReactNode;
}

const SwitchPrimary: FC<IProps> = function ({
  className = '',
  type = 'checkbox',
  onChange,
  children,
  checked,
  defaultChecked,
  value,
  defaultValue,
  label,
  name,
  inactive,
}): ReactElement {
  const [check, setCheck] = useState(false);

  const onInput: ReactEventHandler = (e): void => {
    onChange && onChange(e);

    if (inactive) {
      const { checked } = e.target as any;

      setCheck(checked);
    }
  };

  const params = {
    onChange: onInput,
    type,
    value,
    defaultValue,
    name,
    label,
    checked,
    defaultChecked,
  };

  return (
    <div className={`switch--primary ${className} ${check ? 'check' : ''}`}>
      <CustomSwitch {...params} />
      {children}
    </div>
  );
};

export default SwitchPrimary;
