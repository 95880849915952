import React, { FC, ReactElement, ReactText } from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  data?: Array<{
    title: ReactText;
    path: string;
    children?: Array<{
      title: ReactText;
      path: string;
    }>;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeButton?(path: any): void;
}

const Navigation: FC<IProps> = function ({ data, onChangeButton }): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleButton = (path: any) => {
    onChangeButton && onChangeButton(path);
  };

  const renderItem = () => {
    return data?.map((item, i) => (
      <div className="navigation__item" key={i}>
        <NavLink className={`navigation__link`} onClick={toggleButton} to={item.path} exact>
          {item.title}
        </NavLink>
        {item?.children && (
          <div className="navigation__children">
            {item?.children.map((child, index) => (
              <div className="navigation__item" key={index}>
                <NavLink className={`navigation__link`} onClick={() => toggleButton(child.path)} to={child.path} exact>
                  {child.title}
                </NavLink>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return <div className="navigation">{renderItem()}</div>;
};

export default Navigation;
