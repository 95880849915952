// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { $body, $doc } from '../../../helpers';

interface IProps {
  children: ReactNode;
  close?(element: Element): void;
}

const CustomPortal: FC<IProps> = ({ children, close }): ReactElement => {
  const element = document.createElement('div');

  const closePortal: EventListener = (e) => {
    const { target } = e;

    if (target instanceof Element) {
      close && close(target);
    }
  };

  useEffect(() => {
    $body.appendChild(element);
    element.className = `custom-portal`;
    $doc.addEventListener('click', closePortal);

    return function cleanUp() {
      $body.removeChild(element);
      $doc.removeEventListener('click', closePortal);
    };
  });

  return createPortal(children, element);
};

export default CustomPortal;
