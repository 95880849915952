import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  label?: ReactNode;
  type?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: Function;
}

const CustomSwitch: FC<IProps> = function ({
  type = 'checkbox',
  onChange,
  value,
  label,
  name,
  checked,
  defaultValue,
  defaultChecked,
}): ReactElement {
  const onInput: ReactEventHandler = (e): void => {
    onChange && onChange(e);
  };

  const isParams = {
    onChange: onInput,
    ...((type === 'checkbox' || type === 'radio') && { type }),
    value,
    defaultValue,
    defaultChecked,
    name,
    checked,
  };

  return (
    <div className="custom-switch">
      <label>
        <input {...isParams} />
        <span className="custom-switch__to" />
        {label && <span className="custom-switch__label">{label}</span>}
      </label>
    </div>
  );
};

export default CustomSwitch;
