import { IProfileAction, IProfileState } from '../../services/IProfile';
import types from './types';

const initialState: IProfileState = {
  worksheetList: null,
  errorMessage: null,
};

export default function profileReducer(state: IProfileState = initialState, action: IProfileAction): IProfileState {
  switch (action.type) {
    case types.WORKSHEET_LIST:
      return { ...state, worksheetList: action.worksheetList };

    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    default:
      return state;
  }
}
