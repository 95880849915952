import { get, map } from 'lodash';
import moment from 'moment';
import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { CustomScroll, SelectPrimary } from '../index';

const monthsArray = [
  {
    Январь: 'January',
    Февраль: 'February',
    Март: 'March',
    Апрель: 'April',
    Май: 'May',
    Июнь: 'June',
    Июль: 'July',
    Август: 'August',
    Сентябрь: 'September',
    Октябрь: 'October',
    Ноябрь: 'November',
    Декабрь: 'December',
  },
];

interface IProps {
  label?: string;
  runYears: Array<number>;
  placeholder: Array<string>;
  selected?: Array<string> | null;
  error?: string;
  onChange?(timeStamp: string): void;
}

const SelectBorn: FC<IProps> = ({ label, placeholder, runYears, error, onChange, selected }): ReactElement => {
  const [typeItems, setTypeItems] = useState<{ [key: string]: Array<number | string> }>({
    day: [],
    month: [],
    year: [],
  });
  const [state, setState] = useState({
    isChange: false
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [bornSelect, setBornSelect] = useState<any>({
    day: '',
    month: '',
    year: '',
  });

  const renderYears: Function = (): Array<number> => {
    const [minRange, maxRange] = runYears;

    const min = minRange;

    const max = min + (maxRange - minRange);

    let newArrayYears: Array<number> = [];

    for (let i = min; i <= max; i++) {
      newArrayYears = [...newArrayYears, i];
    }
    return newArrayYears.reverse();
  };

  const renderMonths: Function = (): Array<string> => {
    return map(monthsArray[0], (field, key) => key);
  };

  const renderDays: Function = (monthed: string): Array<number | string> | null => {
    const { year, month } = bornSelect;

    if (month === '' || monthed === '') return null;
    const monthIndex = moment()
      .month(monthsArray[0][monthed ? monthed : month])
      .format('M');

    const arr = moment(`${year}-${monthIndex}`, 'YYYY-MM').daysInMonth();

    return Array.from(Array(arr), (_, i) => {
      const next = i + 1;

      return next;
    });
  };

  useEffect(() => {
    setBornSelect({
      day: selected ? get(selected, '[0]', '') : 1,
      month: selected ? get(selected, '[1]', '') : 'Январь',
      year: selected ? get(selected, '[2]', '') : 1990,
    });
  }, [selected]);

  useEffect(() => {
    setTypeItems({
      day:
        renderDays() !== null
          ? renderDays()
          : (() => {
            const days = Array.from(new Array(32).keys());

            days.shift();
            return days;
          })(),
      month: renderMonths(),
      year: renderYears(),
    });
  }, [bornSelect]);

  const getTimeStamp: Function = ({ day, month, year }: { [key: string]: number | string }): string => {
    if (day !== '' && month !== '' && year !== '') {
      return moment(`${day} ${monthsArray[0][month]} ${year}`).unix() + '';
    } else {
      return '';
    }
  };

  const onChangeSelect: Function = (name: string | number, key: string): void => {
    let timeStamp;

    if (key !== 'month') {
      setBornSelect({
        ...bornSelect,
        [key]: name,
      });

      timeStamp = getTimeStamp({
        ...bornSelect,
        [key]: name,
      });
    } else {
      const days = renderDays(name);

      setBornSelect({
        ...bornSelect,
        [key]: name,
        ...(name === 'Февраль' &&
          (+bornSelect.day === 29 || +bornSelect.day === 30 || +bornSelect.day === 31) && {
            ['day']: days[days.length - 1],
          }),
      });

      timeStamp = getTimeStamp({
        ...bornSelect,
        [key]: name,
        ...(name === 'Февраль' &&
          (+bornSelect.day === 29 || +bornSelect.day === 30 || +bornSelect.day === 31) && {
            ['day']: days[days.length - 1],
          }),
      });
    }

    onChange && onChange(timeStamp);
    setState({
      ...state,
      isChange: true
    })
  };

  // const renderSelects: Function = (placeholder, selectKey, selected): ReactNode => {
  //   return placeholder.map((item, i) => {
  //     const selectKey = Object.keys(bornSelect)[i];
  //
  //     const selected = Object.values(bornSelect)[i];
  //
  //     return (
  //       <SelectPrimary selection={selected} placeholder={item} className={error} key={i}>
  //         <CustomScroll>
  //           {typeItems[selectKey].map((option: number | string, index: number) => {
  //             const isActive = `${option}` === `${selected}` ? 'active' : '';
  //
  //             return (
  //               <div
  //                 className={`custom-select__options ${isActive}`}
  //                 onClick={() => onChangeSelect(option, selectKey)}
  //                 key={index}
  //               >
  //                 {option}
  //               </div>
  //             );
  //           })}
  //         </CustomScroll>
  //       </SelectPrimary>
  //     );
  //   });
  // };

  const renderSelects: Function = (placeholder, selectKey, selected): ReactNode => {
    const raw = renderDays();

    const test = [1, 2, 3, 4, 5];

    const isOut = isNaN(selected)
      ? (() => (typeItems[selectKey].includes(selected) ? selected : (() => (raw ? raw[raw.length - 1] : test))()))()
      : typeItems[selectKey].includes(+selected)
        ? selected
        : (() => (raw ? raw[raw.length - 1] : test))();

    return (
      <SelectPrimary selection={isOut} placeholder={placeholder} className={error} isChange={state.isChange}>
        <CustomScroll>
          {typeItems[selectKey]?.map((option: number | string, index: number) => {
            const isActive = `${option}` === `${selected}` ? 'active' : '';

            return (
              <div
                className={`custom-select__options ${isActive}`}
                onClick={() => onChangeSelect(option, selectKey)}
                key={index}
              >
                {option}
              </div>
            );
          })}
        </CustomScroll>
      </SelectPrimary>
    );
  };

  return (
    <div className="select--born">
      {label && <label>{label}</label>}
      {renderSelects(placeholder[0], 'day', bornSelect.day)}
      {renderSelects(placeholder[1], 'month', bornSelect.month)}
      {renderSelects(placeholder[2], 'year', bornSelect.year)}
    </div>
  );
};

export default SelectBorn;
