import { IAdditionAction, IAdditionState } from '../../services/IAddition';
import types from './types';

const initialState: IAdditionState = {
  hardSkills: null,
  softSkills: null,
  businessSkills: null,
  lessonSkills: null,
  sendingSupportRequest: false,
};

export default function additionReducer(state: IAdditionState = initialState, action: IAdditionAction): IAdditionState {
  switch (action.type) {
    case types.GET_HARD_SKILLS:
      return { ...state, hardSkills: action.hardSkills };

    case types.GET_SOFT_SKILLS:
      return { ...state, softSkills: action.softSkills };

    case types.GET_BUSINESS_SKILLS:
      return { ...state, businessSkills: action.businessSkills };

    case types.GET_LESSON_SKILLS:
      return { ...state, lessonSkills: action.lessonSkills };

    case types.SET_SENDING_SUPPORT:
      return { ...state, sendingSupportRequest: action.sendingSupportRequest }

    default:
      return state;
  }
}
