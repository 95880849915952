import React, { FC, ReactElement, ReactEventHandler, ReactNode, useEffect, useRef } from 'react';
import { $doc } from '../../../helpers';
import { useCustomToggle } from '../../hooks';

interface IProps {
  children: ReactNode;
  selection: ReactNode;
  placeholder: ReactNode;
  className?: string;
}

const CustomSelect: FC<IProps> = ({ selection, children, placeholder, className = '' }): ReactElement => {
  const [toggle, onToggle] = useCustomToggle(false);

  const wrapper = useRef(null);

  const closeSelect: EventListener = (e): void => {
    const search = e.target as Node;

    const target: HTMLDivElement = wrapper.current!;

    if (target instanceof HTMLDivElement) {
      if (!target.contains(search)) {
        onToggle(false);
      }
    }
  };

  useEffect(() => {
    $doc.addEventListener('click', closeSelect);

    return function cleanUp() {
      $doc.removeEventListener('click', closeSelect);
    };
  });

  const toggleSelect: ReactEventHandler = (): void => {
    onToggle();
  };

  const renderMenuItem: Function = (): ReactNode => (
    <div className="custom-select__selection" onClick={toggleSelect}>
      {children}
    </div>
  );

  const isActiveSelection = toggle ? 'active' : '';

  return (
    <div className={`custom-select ${className}`} ref={wrapper}>
      <div className={'custom-select__selected ' + isActiveSelection} onClick={toggleSelect}>
        {selection ? selection : placeholder ? placeholder : ''}
      </div>
      {toggle && renderMenuItem()}
    </div>
  );
};

export default CustomSelect;
