import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  toggle?: boolean;
  children?: ReactNode;
  onToggle?(): void;
  className?: string;
}

const ButtonPrimary: FC<IProps> = function ({ toggle, children, onToggle, className = '' }): ReactElement {
  const onClickButton: ReactEventHandler = (): void => {
    onToggle && onToggle();
  };

  const renderButton: Function = (): ReactNode => {
    const isActive = toggle ? 'active' : '';

    return (
      <div className={`button--primary ${className} ${isActive}`} onClick={onClickButton}>
        {children && children}
      </div>
    );
  };

  return renderButton();
};

export default ButtonPrimary;
