import { IHomeAction, IHomeState } from '../../services/IHome';
import types from './types';

const initialState: IHomeState = {
  sliderList: null,
  contacts: null,
  settings: null,
};

export default function homeReducer(state: IHomeState = initialState, action: IHomeAction): IHomeState {
  switch (action.type) {
    case types.GET_SLIDER_LIST:
      return { ...state, sliderList: action.sliderList };

    case types.GET_CONTACTS:
      return { ...state, contacts: action.contacts };

    case types.GET_SETTINGS:
      return { ...state, settings: action.settings };

    default:
      return state;
  }
}
