// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function checkExpires(itemName: string, defaults: any): any {
  const data = localStorage.getItem(itemName);

  if (!data) return defaults;

  if (itemName === 'access_token') {
    return data;
  }

  return data[0] === '{' ? JSON.parse(data) : eval(data); //TODO
}
