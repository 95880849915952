interface IObject {
  [key: string]: string | number;
}

export default function createQueryParams(object: IObject | {}) {
  let params = '?';

  for (const key in object) {
    const field = object[key];

    params += field && field !== '' ? `${key}=${field}&` : '';
  }
  return params.substring(-1, params.length - 1);
}
