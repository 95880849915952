import { IGeneralAction, IGeneralState } from '../../services/IGeneral';
import { checkExpires } from '../../utils';
import types from './types';

const initialState: IGeneralState = {
  localName: checkExpires('localName', 'RU'),
  message: null,
};

export default function generalReducer(state: IGeneralState = initialState, action: IGeneralAction): IGeneralState {
  switch (action.type) {
    case types.LOCAL_NAME:
      return { ...state, localName: action.localName };
    case types.SET_MESSAGE:
      return { ...state, message: action.message };
    case types.CLEAR_MESSAGE:
      return { ...state, message: null };
    default:
      return state;
  }
}
