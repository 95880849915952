import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  label?: ReactNode;
  children?: ReactNode;
  avatarUrl?: string | null;
  avatarDefault?: string;
  uploadFiles?: Function | ReactEventHandler;
  active?: boolean;
}

const DropZonePrimary: FC<IProps> = function ({
  uploadFiles,
  label,
  children,
  avatarUrl,
  avatarDefault,
  active = true,
}): ReactElement {
  const upload: ReactEventHandler = (e): void => {
    uploadFiles && uploadFiles(e);
  };

  const renderInput: Function = (): ReactNode => {
    return (
      <div className="dropzone--primary">
        {active && (
          <label>
            <input type="file" formEncType="" onChange={upload} />
          </label>
        )}
        <div className="dropzone--primary__thumb">
          <img src={avatarUrl ? avatarUrl : avatarDefault} alt="avatar" />
        </div>
        {label && <label>{label}</label>}
        {children}
      </div>
    );
  };

  return renderInput();
};

export default DropZonePrimary;
